import React from "react"
import { v4 } from 'uuid'
import styled from "styled-components"
import Img from 'gatsby-image'
import BreakpointUp from "../Media/BreakpointUp"

const Card = styled.div`
	margin-bottom:30px;
	display:flex;
	align-items:center;
    background: #F6F6F6;
	flex-direction: column;
	position:relative;
	
`
const CardTitle = styled.h3`
	margin:0 0 10px;
	color:#020304;
	font-size:20px;
	line-height:28px;
	font-weight:700;
	font-family: 'Roboto', serif;
`
const CardFigure = styled.div`
	width: 100%;
	position:relative;
	height: 236px;
	& .gatsby-image-wrapper{
		height:100%;
	}
	&:after {		
        content: "";
        position: absolute;	
        bottom: 0;
        left: 0;
        width: 100%;
        height:25%;
        background: -moz-linear-gradient(bottom,  rgba(255,252,237,1) 0%, rgba(255,255,255,0.01) 99%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(bottom,  rgba(255,252,237,1) 0%,rgba(255,255,255,0.01) 99%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to top,  rgba(255,252,237,1) 0%,rgba(255,255,255,0.01) 99%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F6F6F6', endColorstr='#00ffffff',GradientType=0 );
	}
`
const CardContent = styled.div`
	position:relative;
	z-index:1;
	padding:20px 0;
`
const CardDescription = styled.p`
	font-size:18px;
	color:#231F20;
	line-height:28px;
	margin-bottom:0;
`
const StepGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1,1fr);
	grid-template-rows: auto;
	grid-column-gap: 15px;
	padding:0 15px;
	${BreakpointUp.sm`				
		grid-template-columns: repeat(2,1fr);		
		padding:0;
	`}
	${BreakpointUp.lg`				
		grid-column-gap: 30px;
		grid-template-columns: repeat(4,1fr);		
	`}
`
const StepNumber = styled.div`
	width:50px;
	height:50px;
	border-radius:50%;
	background-color:#FFFCED;
	color:#E29C00;
	font-family: 'IBM Plex Serif', serif;
	font-size:28px;
	line-height:50px;
	font-weight:700;
	position:absolute;
	left:-25px;
	top:-25px;
	z-index:1;
	text-align:center;
`
const PaymentStep = ({ data }) => {
	return(			
		<StepGrid>
			{
				data.map(item=>{
					return(
						<Card className="card" key={v4()}>
							<StepNumber>{item.order}</StepNumber>
							<CardFigure className="card-img">
								<Img fluid={item.image.fluid} />
							</CardFigure>
							<CardContent className="card-body">
								<CardTitle>{item.title}</CardTitle>
								<CardDescription>{item.description.description}</CardDescription>
							</CardContent>
						</Card>
					)
				})
			}
		</StepGrid>		
	)
}


export default PaymentStep