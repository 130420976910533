import React, { Component } from 'react'
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "../ProductCard"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import LeftArrowIcon from "../../components/Icons/LeftArrowIcon"

const SlickItem = styled.div`
  display:block !important;
  outline:none;
  max-width:100%;
  padding:10px;
  ${BreakpointUp.lg`				
    padding:10px 12px;    
  `}
  
  & .card{
    position: relative;
    flex-direction: column-reverse;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
  }
  & .card-body{
    width:100%;
    ${BreakpointDown.sm`				
      padding:35px 15px;    
    `}    
  }
  & .card-img{
    width:100%;
    height: auto;
    ${BreakpointUp.lg`
      height: 322px;
    `}
  }
  & .measurement{
    display:none;
  }
`
const SliderContent = styled.div`
  .slick-arrow{   
    top:25%;
  }
  .slick-prev{
    left:-15px;
  }
  .slick-next{
    right:-15px;
  }

`
const RightArrow = styled.div`
  display: none;
  position: absolute;
  top: 450px;
  right: 0px;
  width: 50px;
  height: 50px;
  padding: 12px 5px;
  border-radius: 50px;
  background: white;
  border: 1px solid #ddd;
  text-align: center;
  z-index: 10;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all .3s ease;
      transform: translateX(5px);
    }
  }
  @media(max-width: 767px) {
    display: block;
  }
`
const LeftArrow = styled.div`
  display: none;
  position: absolute;
  top: 450px;
  left: 0px;
  width: 50px;
  height: 50px;
  padding: 12px 5px;
  border-radius: 50px;
  border: 1px solid #ddd; 
  background: white;
  z-index: 10;
  text-align: center;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all .3s ease-in-out;
      transform: translateX(-5px);
    }
  }
  @media(max-width: 767px) {
    display: block;
  }
`

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowIcon/></button>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowIcon/></button>
  );
}

export default class RelatedProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 3,
      speed: 500,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows:false,
            centerMode: true,
            centerPadding: '10px',            
            infinite: true

          }
        }
      ]     
    };
    const { data } = this.props
    return (    
      <SliderContent>     
        <Slider {...settings} ref={c => (this.slider = c)}>
          {
            data.edges.map((item, i) => {
              return(
                <SlickItem key={i}>
                  <ProductCard data={item.node} showQuote={this.props.showQuote}  />
                </SlickItem>
              )
            })
          }
         
        </Slider>
        <LeftArrow onClick={this.previous}>
           <LeftArrowIcon fill="#020304" />
        </LeftArrow>
        <RightArrow onClick={this.next}>
          <ArrowIcon fill="#020304" />
        </RightArrow>
      </SliderContent>
    );
  }
}
