import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import DefaultOutlineButton from "../button/DefaultOutlineButton"
import { Section, SectionTitle, SectionLead } from "../section"
import PhoneIcon from "../Icons/PhoneIcon"
import BreakpointDown from "../../components/Media/BreakpointDown"

const DoubleFrameBg = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "doubleframe.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const TextSection = styled.div`
	display:flex;
	align-items: center;
	justify-content: space-between;
	& h2, & p{
		text-align:left;
		margin:0;	
	}
	& h2{
		padding:20px 0;
	}
	${BreakpointDown.xl`	
		flex-direction: column;
		& h2, & p{			
			text-align:center;
		}
    `}
`
const SectionContent = styled.div`
	padding:15px 0;
`
const ButtonWrap = styled.div`
	& .btn{
		padding:20px 40px;
		font-size:22px;
	}
`
const BackgroundImage = styled.div`
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left:0;
	background-repeat: no-repeat;
	background-position: left top;
	&:after{
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(2,3,4,1) 65%);
		background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(2,3,4,1) 65%);
		background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(2,3,4,1) 65%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#020304',GradientType=1 );
	}
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`

const CallToAction = ({ sectionData }) => {
	return(						
    <Section bg="#020304" pt="130px" pb="130px" xpt="60px" xpb="60px">
    <BackgroundImage>
    	<DoubleFrameBg />
    </BackgroundImage>
		<div className="container">
			<TextSection>
				<SectionContent>
					<SectionLead color="#fff">{sectionData.sectionTitle}</SectionLead>				
					<SectionTitle color="#fff" ml="0" mb="0" maxWidth="700px" >{sectionData.subTitle}</SectionTitle>
				</SectionContent>
				<ButtonWrap>
					<a href="tel:8776005980">
						<DefaultOutlineButton icon={<PhoneIcon />} text="(877) 600-5980" />
					</a>
				</ButtonWrap>
			</TextSection>
		</div>	
	</Section>			
	)
}


export default CallToAction